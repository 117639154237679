<template>
  <div class="header">
    <img src="../assets/icons/logo2.png" class="logo" @click="goHome">
    <img src="../assets/icons/mlogo2.svg" class="mlogo" @click="goHome">
    <div class="lint_to">
      <router-link to="/home">首页</router-link>
      <router-link to="/download">青少年心理援助系统</router-link>
        <router-link to="/download2">咨询师APP</router-link>
      <router-link to="/about">关于我们</router-link>
      <!-- <a href="https://www.baidu.com" target="_blank">操作手册</a> -->
    </div>
    <img src="../assets/icons/mobile_menu.svg" class="mobile_menu" @click="changevalue">
  </div>
  <div class="moblie_nav" v-show="drawer">
      <router-link to="/home">首页</router-link>
      <router-link to="/download">青少年心理援助系统</router-link>
      <router-link to="/download2">咨询师APP</router-link>
      <router-link to="/about">关于我们</router-link>
  </div>
  <!-- <el-drawer
    v-model="drawer"
    title="初相位"
    :with-header="false"
    direction="rtl"

    :close-on-click-modal="true"
  >
  <div class="goluyou">
    <router-link to="/home">首页</router-link>
    <router-link to="/download">产品下载</router-link>
    <router-link to="/about">关于我们</router-link>
  </div>

  </el-drawer> -->
</template>
<script setup>
import {ref,watch} from 'vue'
import { useRouter } from 'vue-router';
import { ElDrawer } from 'element-plus'
import 'element-plus/dist/index.css'
const router = useRouter();
function goHome(){
    router.push({path:'/home'})
}
const drawer = ref(false)
// 监听路由变化事件
watch(router.currentRoute, () => {
  drawer.value=false
});
const changevalue = () => {
    drawer.value = !drawer.value;
};
</script>
<style scoped>
  .header{width: 100%;height: 100rem;padding:0 7.34375%;display: flex;align-items: center;justify-content: space-between;
background: #fff;float: left;position: fixed;top: 0;left: 0;z-index: 100;}
  .logo{width: 276rem;height: 40rem;}
  .lint_to a{font-size: 16rem;font-weight: 400;color: #3E3E3E;margin-right: 50rem;position: relative;}
  .lint_to a:hover,a.router-link-active{font-weight: 600;}

  .lint_to a::before{
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    background: #000;
    width: 0;
    left: 0;
    bottom: -10rem;
    transition: width .5s;
  }
  a.router-link-active::before{
    width: 100%;
  }
  .lint_to a:hover::before {width: 100%;}
  .mobile_menu{display: none;}
  .mlogo{display: none;}

  @media screen and (max-width: 750px) {
  .mobile_menu{display: inline-block;width:.48rem;height: .48rem;cursor: pointer;}
  .lint_to{display: none;}
    .logo{display: none;}
    .mlogo{display: block;width: 3.87rem;height: .56rem;}
  
  .header{width: 100%;height: 1.60rem;padding:0 7.34375%;display: flex;align-items: center;box-sizing:border-box;justify-content: space-between;
    background: #fff;}
  .logo{width: 1.532rem;height: .40rem;}

  .goluyou{display: flex;flex-direction: column;padding-top: 0.5rem;}
  .goluyou a{font-size: 0.16rem;margin-bottom: 0.2rem;}

  .moblie_nav{width: 2.4rem;
      height: 4rem;
      background: #FFFFFF;
      box-shadow: 0 .10rem .40rem .2rem rgba(203,203,202,0.3);text-align: center;
      border-radius: .12rem;position: fixed;top: 1.18rem;right: 0.55rem;z-index: 999;}
      .moblie_nav a{font-size: .26rem;display: block;font-weight: 400;color: #333333;height: 1rem;line-height: 1rem;text-align:center;border-bottom: 0.01rem solid #f4f4f4;padding: 0 0.5rem;}
      a.router-link-active{
        font-weight: 550;
      }
      
      a{
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
      }
}
</style>